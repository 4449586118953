import langs from "@/plugins/lang"
import helpers from '@/plugins/helper'
import AuthService from "@/services/auth"
import toastPlugin from "@/plugins/toast"
import { useCookies } from "vue3-cookies"

export  const getters = {
  remindLogin: state => state.remindLogin
};

export const getDefaultState = () => ({
  accessToken: null,
  remindLogin: JSON.parse(localStorage.getItem("remindLogin")) || false
})

export const state = getDefaultState

export  const actions = {

  async login({commit}, payload) {

    const data = {
      id: payload.id,
      password: payload.password
    }
    const res = await AuthService.login(data)
  
    if(res.status === 200) {
      commit("SET_REMIND_LOGIN", payload)
      commit("SET_ACCESS_TOKEN", res.data)
      return true
    }
      
    const listErr = helpers.showListError(res.status, res.data)   
    toastPlugin.error(listErr)
  },

  async forgot({commit}, payload) {
    const {email} = payload 
    const data = {email}
    const res = await AuthService.forgot(data)
    if(res.status === 200) {
      toastPlugin.success(langs.SEND_OTP_SUCCESS)
      return true
    }

    const listErr = helpers.showListError(res.status, res.data)   
    toastPlugin.error(listErr)
  },

  async verifyOtp({commit}, payload) {
    const {email, otp} = payload 
    const data = {
      email,
      otp
    }
    const res = await AuthService.verifyOtp(data)
    if(res.status === 200) {
      return {
        status: true,
        data: res.data.data
      }
    }

    const listErr = helpers.showListError(res.status, res.data)   
    toastPlugin.error(listErr)
  },

  async resendOtp({commit}, payload) {
    const {email} = payload 
    const data = {email}
    const res = await AuthService.forgot(data)
    if(res.status === 200) {
      toastPlugin.success(langs.RESEND_OTP_SUCCESS)
      return true
    }

    const listErr = helpers.showListError(res.status, res.data)   
    toastPlugin.error(listErr)
  },

  async changePassword({commit}, payload) {
    const {password, passwordConfirmation, token} = payload
    const data = {
      token: token,
      password: password,
      password_confirmation: passwordConfirmation
    }

    const res = await AuthService.changePassword(data)
    if(res.status === 200) {
      toastPlugin.success(langs.RESET_PASS_SUCCESS)
      return true
    }

    const listErr = helpers.showListError(res.status, res.data)   
    toastPlugin.error(listErr)
  },

  async logout({commit}, payload) {
    const { cookies } = useCookies()
    cookies.remove("accessToken")

    return true

  },

  reset({commit}) {
    commit('RESET')
  }
 }

export  const mutations = {

  SET_REMIND_LOGIN(state, data) {
    const { remindLogin } = data
    state.remindLogin = remindLogin
    if(remindLogin) {
      localStorage.setItem("remindData", JSON.stringify({
        id: data.id,
        password: data.password
      }))
      localStorage.setItem("remindLogin", true)
    }else {
      localStorage.removeItem("remindData")
      localStorage.removeItem("remindLogin")
    }
  },

  SET_ACCESS_TOKEN(state, data) {
    const { cookies } = useCookies()
    state.accessToken = data.access_token 
    cookies.set("accessToken", data.access_token )
  },

  RESET(state) {
    Object.assign(state, getDefaultState())
  }
}


export default {
  namespaced: true,
  getters,
  state,
  actions,
  mutations
}
