import { createStore } from 'vuex'
import medicalModule from '@/store/medical'
import profileModule from '@/store/profile'
import componentModule from '@/store/component'
import authModule from '@/store/auth'
import attributeModule from '@/store/attribute'

// Create a new store instance.
export default createStore({
  modules: {
    medical: medicalModule,
    component: componentModule,
    profile: profileModule,
    auth: authModule,
    attribute: attributeModule
  },

  
})