import Api from '@/plugins/api'
import helpers from "@/plugins/helper"

export default {

  prefix: '/profile',

  async fetch() {
    try {
      return await Api.get(`${this.prefix}`);
    } catch (error) {
      return helpers.getError(error) || false; 
    }
  },

  async update(data) {
  	try {
      return await Api.put(`${this.prefix}/me`, data);
    } catch (error) {
      return helpers.getError(error) || false; 
    }
  }

}
