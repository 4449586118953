<template>
<div :class="showMenu ? `sb-sidenav-toggled` : ``">
  <Header />
  <router-view></router-view>
</div>
</template>

<script type="text/javascript">
import Header from "@/views/layouts/partials/Header"  
import { computed } from 'vue'
import { useStore } from "vuex"

export default {
  components: {
    Header,
  },

  setup() { 
    const store = useStore()
    store.dispatch("profile/fetch", {})
    return {
    	showMenu: computed(() => store.getters["component/showMenu"]),
    }
  },

}

</script>