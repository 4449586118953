<template>
  <form class="loginbox_c resetpass otp-block validation fz16" id="otpform">
    <div class="btn-left">
      <RouterLink to="/forgot"></RouterLink>
    </div>
    <h2 class="text_center title">認証コードを入力してください</h2>
    <p class="text_center text_note">メールアドレスに送信した認証コードを入力してください</p>
    <div class="number flexbox">
      <div class="number-box flexbox">
        <v-otp-input
          ref="otpInput"
          input-classes="otp-input"
          separator="-"
          :num-inputs="4"
          :should-auto-focus="true"
          :is-input-num="true"
          @on-complete="handleOnComplete"
        />
      </div>
    </div>
    <div class="text_center resendmail">
      <a class="maincl text_bold" href="javascript:void(0)" @click="resendOtp()">メールを再送する</a>
    </div>
  </form>
</template>


<script type="text/javascript">
import VOtpInput from 'vue3-otp-input'
import { useStore } from "vuex"
import { useRouter, useRoute } from "vue-router"
import toastPlugin from "@/plugins/toast"

export default {

  components: {
    VOtpInput,
  },

  setup() {
    const router = useRouter()
    const route = useRoute()
    const store = useStore()

    function handleOnComplete(value) {
      
      const payload = {
        otp: value,
        email: route.query.email
      }

      store.dispatch("auth/verifyOtp", payload).then(res => {
        if(res.status === true) {
          router.push({name: "ResetPass", query: {token: res.data.token}})
        }
      })
    }

    function resendOtp() {
      store.dispatch("auth/resendOtp", {email: route.query.email})
    }

    return {
      handleOnComplete,
      resendOtp
    }
  }
}
</script>

<style>
.otp-input {
  width: 80px;
  height: 80px;
  padding: 5px;
  margin: 0 10px;
  font-size: 40px;
  border-radius: 4px;
  border: 1px solid #02A3AF;
  text-align: center;
  color: #02A3AF;
}
</style>
