import store from '@/store'

export default {
  showModal(key, val) {
    store.commit("medical/SHOW_MODAL", {
      key: key,
      val: val
    })

    store.commit("component/SHOW_OVERLAY", val)
  },

  showMenu(key, val) {

    store.commit("component/SHOW_MENU", val)

  }
}