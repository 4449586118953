<template>
  <div class="resetpass loginbox_c fz16">
    <div class="btn-left">
      <a @click="router.go(-1)"></a>
    </div>
    <h2 class="text_center title">パスワード再設定</h2>
    <p class="text_center text_note">
       パスワードを再設定します。<br>
      下記入力欄にご希望のパスワードを記入の上、<br>
      登録ボタンを押してください。
    </p>

    <Form 
    class="formlogin"
    :validation-schema="schema"
    @submit="onSubmit"

    >
      <div class="formlogin">
        <div class="formbox_e">
          <div class="fsc boxpassword">
              <Field 
              class="form-control pass_log_id" 
              :type="typePassword" 
              maxlength="15"  
              placeholder="パスワード"
              name="password" 
              />

              <span @click="typePassword = typePassword === `password` ? `text` : `password`">
                <i class="fa fa-eye toggle-password"></i>
              </span>    
          </div>
          <ErrorMessage 
            class="error"
            name="password"
            as="div"
          /> 
        </div>
        <div class="formbox_e">
          <div class="fsc boxpassword">
            <Field 
            class="form-control pass_log_id" 
            :type="typePasswordConfirm" 
            maxlength="15" 
            placeholder="パスワード(確認用)"
            name="passwordConfirmation" 
            />
            <span  @click="typePasswordConfirm = typePasswordConfirm === `password` ? `text` : `password`">
              <i  class="fa fa-eye toggle-password"></i>
            </span>    
          </div>

          <ErrorMessage 
            class="error"
            name="passwordConfirmation"
            as="div"
          /> 

        </div>
        <div class="forbox_e boxbtn text-center">
          <button class="btn btn-large btn-primary btn-primary-2">登録</button>
        </div>
      </div>
    </Form>
  </div>
</template>

<script type="text/javascript">
import { useStore } from 'vuex'
import { useRouter, useRoute } from 'vue-router'
import resetSchema from '@/schema/auth/reset'
 
export default {

  setup() {
    const router = useRouter()
    const route = useRoute()
    const store = useStore()
    const schema = resetSchema

    function onSubmit(data) {
      data.token = route.query.token
      store.dispatch("auth/changePassword", data).then(res => {
        if(res === true) {
          router.push({name: "Login"})
        }
      })
    }

    return {
      onSubmit,
      schema,
      router,
      store
    }
  },

  data() {
    return {
      typePassword: "password",
      typePasswordConfirm: "password"
    }
  },

}
</script>