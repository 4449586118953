import axios from "axios"
import { useCookies } from "vue3-cookies";


global.axiosInstance = require('axios')
global.axiosInstance.defaults.baseURL = process.env.VUE_APP_API_URL || `http://127.0.0.1:8000/api`;


axiosInstance.interceptors.request.use(
  async config => {

    const { cookies } = useCookies();
    const accessToken = cookies.get('accessToken')

    if(accessToken) {
      config.headers.Authorization = 'Bearer ' + cookies.get('accessToken')
    }

    config.headers.Accept = 'application/json'

    return config;
  },
  error => {
    Promise.reject(error)
});

// Response interceptors for API
axiosInstance.interceptors.response.use((response) => {
  return response
},
(error) => {

  const originalRequest = error.config;

  // if no login || token expired => 
  if(error.response.status === 401)
  {
    const { cookies } = useCookies();

    cookies.remove("accessToken")
    window.location.href = "/login"
  }

  return Promise.reject(error);
});

export default axiosInstance
