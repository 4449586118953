export default {

  validation: {
    REQUIRED(field, message) {
      if(message) {
        return `${field + ' ' + message}`
      }
      return `${field}は必須です。`
    },
    EMAIL(field, message) {
      return `有効なメールアドレスを入力してください。`
    },

    PASSWORD_CONFIRMATION() {
      return 'Password does not match.'
    }
  },

  RESEND_OTP_SUCCESS: "Resend otp success",
  RESET_PASS_SUCCESS: "Change your password success",
  SEND_OTP_SUCCESS: "ご登録いただいたメールアドレス宛に、パスワード再設定用のURLリンクを送信しました。",
  
  UPDATE_PROFILE_SUCCEED: "処理が完了しました。"
}