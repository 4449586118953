<template>
<div class="secondpage navstate_show page-login">
  <component :is="layout">
    <router-view></router-view>
  </component>

  <div :class="[`modal-backdrop`, showOverlay ? `show` : `fade`]" ></div>
</div>
</template>

<style lang="scss">
.secondpage {
  height: inherit;
}
</style>

<script>

import { mapGetters} from 'vuex'

export default {
  name: "app",

  computed: {

    ...mapGetters({
      showOverlay: 'component/showOverlay'
    }),

    layout () {
      let getLayout = this.$route.meta.layout || "empty"
      return getLayout
    }
  },

}
</script>
