import Api from '@/plugins/api'
import helpers from "@/plugins/helper"

export default {

  prefix: '/attributes',

  async fetchAll() {
    try {
      return await Api.get(`${this.prefix}`);
    } catch (error) {
      return helpers.getError(error) || false; 
    }
  },
}
