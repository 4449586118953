import * as yup from "yup"
import langs from "@/plugins/lang"


export default yup.object({
  id: yup
    .string()
    .required(langs.validation.REQUIRED('ログインID')),

  password: yup
    .string()
    .required(langs.validation.REQUIRED('パスワード')),
});

