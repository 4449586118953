<template>

<div class="resetpass loginbox_c fz16">
  <div class="btn-left">
    <RouterLink to="/login"></RouterLink>
  </div>
  <h2 class="text_center title">パスワードリセット</h2>
  <p class="text_center text_note">
     パスワードをリセットします。<br>
    Liexに登録済みのメールアドレスをご入力ください。<br>
    再登録用のURLを送付いたします。
  </p>

  <Form 
  class="formlogin"
  :validation-schema="schema"
  @submit="onSubmit" 
  >
    <div class="formlogin">
      <Field 
      name="email" 
      class="form-control" 
      type="text" 
      placeholder="メールアドレス" 
      maxlength="50" 
      />

      <ErrorMessage 
      class="error"
      name="email"
      />

      <div class="forbox_e boxbtn text-center">
        <button class="btn btn-large btn-primary btn-primary-2">送信</button>
      </div>
    </div>
  </Form>
</div>
       
</template>

<script type="text/javascript">

import { useStore } from "vuex"
import { useRouter } from "vue-router"
import schemaForgot from "@/schema/auth/forgot"

export default {
  setup() {
    const schema = schemaForgot
    const store = useStore()
    const router = useRouter()

    function onSubmit(data) {
      store.dispatch("auth/forgot", data).then(res => {
        if(res === true) {
          router.push({name: 'ForgotOtp', query: {email: data.email} })
        }
      })
    }

    return {
      schema,
      onSubmit
    }
  }
}
</script>