<template>
  <h2>aaa</h2>
</template>

<script type="text/javascript">

import { useRouter } from "vue-router"
import { useStore } from "vuex"

export default {
  setup() {
    const router = useRouter()
    const store = useStore()

    store.dispatch("auth/logout").then(res => {
      if(res === true) {
        router.push("/login")
      }
    })
  }
}
</script>