<template>
  <router-link class="btn btn-danger" :to="url">戻る</router-link>
</template>

<script type="text/javascript">
export default {
  props: {
    url: {
      type: String
    },
  }
}
</script>