import Api from '@/plugins/api'
import helpers from "@/plugins/helper"

export default {

  prefix: '/auth',

  async login(data) {
    try {
      return await Api.post(`${this.prefix}/orthodontist/login`, data);
    } catch (error) {
      return helpers.getError(error) || false; 
    }
  },

  async forgot(data) {
    try {
      return await Api.post(`${this.prefix}/send-forgot-link`, data);
    } catch (error) {
      return helpers.getError(error) || false; 
    }
  },
   
  async verifyOtp(data) {
    try {
      return await Api.post(`${this.prefix}/verify-otp`, data);
    } catch (error) {
      return helpers.getError(error) || false; 
    }
  },

  async changePassword(data) {
    try {
      return await Api.post(`${this.prefix}/reset-password`, data);
    } catch (error) {
      return helpers.getError(error) || false; 
    }
  },
  
}
