<template>
  <div id="layoutSidenav_content">
    <main>
      <div class="container-fluid" v-if="medical">
        <div class="card">
          <div class="card-header">
            <RouterLink to="/medical-manage" class="back"></RouterLink>
            症例相談共有-編集
          </div>
          <div class="card-body">
            <div class="dataTable-container dataTable-medical infoConfirmation">
              <div class="blockHead flexbox">
                <div class="blockHead-left">
                  <dl class="flexbox">
                    <dt>日付</dt>
                    <dd>{{helpers.convertTzTo(medical.created_at, true)}}</dd>
                  </dl>
                  <dl class="flexbox">
                    <dt>カルテ番号</dt>
                    <dd>{{medical.patient ? medical.patient.special_code : ``}}</dd>
                  </dl>
                  <dl class="flexbox">
                    <dt>患者名</dt>
                    <dd>{{medical.patient ? medical.patient.full_name : ``}}</dd>
                  </dl>
                  <dl class="flexbox">
                    <dt>共有先</dt>
                    <dd class="txtMedical">
                      <span v-for="(name, index) in showOrthodontist(medical.orthodontists)" :key="index">
                        {{name}}
                      </span>
                      <!-- <span>{{showOrthodontist(medical.orthodontists)}}</span> -->
                    </dd>
                  </dl>
                </div>
                <div class="blockHead-right">
                  <div class="pdf" v-if="medical.orthodontist_pdf">
                    <div class="btn btn-default btn-pdf" 
                      @click="showPdf(medical.orthodontist_pdf)">
                      <span class="ml20">PDFダウンロード</span>
                    </div>
                  </div>
                  <div class="nodeTxt flexbox" v-if="medical.orthodontist_comment">
                    <div class="txtNotice">
                      <div class="inner"><p>{{ medical.orthodontist_comment }}</p></div>
                    </div>
                    <div class="icon"><img src="/assets/images/user.png" alt=""></div>
                  </div>
                </div>
              </div>
              <div class="blockImage flexbox">
                <div class="blockImage-left">
                  <div class="title flexbox">
                    <p>口腔内画像</p>
                    <p class="txtSmoking">
                      <span>喫煙</span>
                      <span class="ml10">{{medical.is_smoking == '1' ? 'あり' : 'なし'}}</span>
                    </p>
                  </div>
                  <div class="listImages" :class="medical.images.length && medical.images.length >=13 && isShow ? 'hideImages' : 'showImages show'">
                    <div class="row list-galerry">
                      <div class="img-wrapper" v-for="(image, index) in medical.images" :key="index">
                        <UploadImage v-bind:data="image" @uploadImage="selectImage(image)" :readonly="true"></UploadImage>
                      </div>
                    </div>
                    <div :class="medical.images.length && medical.images.length >=13 ? 'loadmore' : 'loadmore hide'" @click="showImages()"><span><img src="/assets/common_img/arrdown.png" alt="" /></span></div>
                  </div>
                  <div class="blockData3D mt40">
                    <dl>
                      <dt>3Dデータ</dt>
                      <dd>
                        <div class="row list-galerry">
                          <div class="list-galerry-item list-galerry-itemply"
                            v-for="(image, index) in medical.files.filter(item => item.file_type === `ply`)" :key="index">
                            <UploadImage :is3d="true" :imageDefault="`/assets/images/ply2.svg`" v-bind:data="image"
                              :readonly="true" @uploadImage="selectImage(image)">
                            </UploadImage>
                          </div>
                        </div>
                      </dd>
                    </dl>
                  </div>
                  <div class="partner-company">
                    <a href="https://r5.dscore.com/#/home ">
                      <img src="/assets/images/dscore.png" alt="" />
                    </a>
                    <a href="https://portal.3shapecommunicate.com/cases">
                      <img src="/assets/images/3shape.png" alt="" />
                    </a>

                    <a href="https://bff.cloud.myitero.com/labs/home">
                      <img src="/assets/images/itero.png" alt="" />
                    </a>
                    <a href="https://www.dental3dcloud.com/u/cases">
                      <img src="/assets/images/dental_cloud.png" alt="" />
                    </a>
                    <a href="https://www.meditlink.com/inbox">
                      <img src="/assets/images/medit_link.png" alt="" />
                    </a>
                  </div>
                </div>
                <div class="blockImage-right">
                  <div class="fcc teeth-wrapper">
                    <Teeth :is="medical.teeth" :teeth="medical.teeth"></Teeth>
                    <p>PCR・EPP・BOP</p>
                  </div>
                </div>
              </div>
              <div class="blockMedicalTable">
                <table class="table table-bordered table-custom">
                  <thead>
                    <tr>
                      <td class="text-bold" rowspan="2">EPP</td>
                      <td class="text-bold">頬側</td>
                      <td class="epp" rowspan="2" v-for="(value, index) in helpers.range(1, 16)" :key="index"
                        :id="`epp${value}`">
                        <table class="table-epp">
                          <tr>
                            <td :class="[`item`, ( checkToothActive(medical.teeth, value)) ? `item-active` : ``]">
                              {{ getActiveAttribute(medical, value, {buccal: ''})[1] || `-` }}</td>
                            <td :class="[`item`, ( checkToothActive(medical.teeth, value)) ? `item-active` : ``]">
                              {{ getActiveAttribute(medical, value, {buccal: ''})[2] || `-` }}</td>
                            <td :class="[`item`, ( checkToothActive(medical.teeth, value)) ? `item-active` : ``]">
                              {{ getActiveAttribute(medical, value, {buccal: ''})[3] || `-` }}</td>
                          </tr>
                          <tr>
                            <td :class="[`item`, ( checkToothActive(medical.teeth, value)) ? `item-active` : ``]">
                              {{ getActiveAttribute(medical, value, {lingual: ''})[1] || `-` }}</td>
                            <td :class="[`item`, ( checkToothActive(medical.teeth, value)) ? `item-active` : ``]">
                              {{ getActiveAttribute(medical, value, {lingual: ''})[2] || `-` }}</td>
                            <td :class="[`item`, ( checkToothActive(medical.teeth, value)) ? `item-active` : ``]">
                              {{ getActiveAttribute(medical, value, {lingual: ''})[3] || `-` }}</td>
                          </tr>
                        </table>
                      </td>
                    </tr>
                    <tr>
                      <td class="text-bold">舌側</td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td class="text-bold" colspan="2">動揺度</td>
                      <td v-for="(value, index) in helpers.range(1, 16)" :key="index"
                        :class="[`item`, ( checkToothActive(medical.teeth, value)) ? `item-active` : ``]">
                        {{ showAgitation(medical.teeth, value) }}
                      </td>
                    </tr>
                    <tr>
                      <td class="text-bold" colspan="2">PCR</td>
                      <td v-for="(value, index) in helpers.range(1, 16)" :key="index"
                        :class="[`item`, ( checkToothActive(medical.teeth, value)) ? `item-active` : ``]">
                        <svg class="pcr-display" xmlns="http://www.w3.org/2000/svg" width="40" height="40"
                          viewBox="0 0 40 40" fill="none" :id="`pcr${value}`">
                          <rect width="40" height="40" fill="#E9E8E8"></rect>
                          <path :class="[`clleft`, getActiveAttribute(medical, value, {pcr: 'left'}) || `` ]"
                            d="M0 40L20 20L0 0V40Z" fill="#E9E8E8"></path>
                          <path :class="[`cltop`, getActiveAttribute(medical, value, {pcr: 'top'}) || ``]"
                            d="M0 -1.74846e-06L20 20L40 0L0 -1.74846e-06Z" fill="#E9E8E8"></path>
                          <path :class="[`clright`, getActiveAttribute(medical, value, {pcr: 'right'}) || ``]"
                            d="M40 0L20 20L40 40L40 0Z" fill="#E9E8E8"></path>
                          <path :class="[`clbottom`, getActiveAttribute(medical, value, {pcr: 'bottom'}) || ``]"
                            d="M40 40L20 20L0 40L40 40Z" fill="#E9E8E8"></path>
                        </svg>
                      </td>
                    </tr>
                    <tr>
                      <td colspan="2" rowspan="2"></td>
                      <td v-for="(value, index) in helpers.range(1, 8)" :key="index"
                        :class="[`item`, ( checkToothActive(medical.teeth, value)) ? `item-active` : ``]">
                        <span :class="[showBleeding(medical.teeth, value) ? `bleed` : ``]">
                          {{ 8 - index }}
                        </span>
                      </td>
                      <td v-for="(value, index) in helpers.range(1, 8)" :key="index"
                        :class="[`item`, ( checkToothActive(medical.teeth, value+8)) ? `item-active` : ``]">
                        <span :class="[showBleeding(medical.teeth, value+8) ? `bleed` : ``]">
                          {{ value }}
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td v-for="(value, index) in helpers.range(1, 8)" :key="index"
                        :class="[`item`, ( checkToothActive(medical.teeth, value+16)) ? `item-active` : ``]">
                        <span :class="[showBleeding(medical.teeth, value+16) ? `bleed` : ``]">
                          {{ 8 - index }}
                        </span>
                      </td>
                      <td v-for="(value, index) in helpers.range(1, 8)" :key="index"
                        :class="[`item`, ( checkToothActive(medical.teeth, value+24)) ? `item-active` : ``]">
                        <span :class="[showBleeding(medical.teeth, value+24) ? `bleed` : ``]">
                          {{ value }}
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td class="text-bold" colspan="2">PCR</td>
                      <td v-for="(value, index) in helpers.range(17, 32)" :key="index"
                        :class="[`item`, ( checkToothActive(medical.teeth, value)) ? `item-active` : ``]">
                        <svg class="pcr-display" xmlns="http://www.w3.org/2000/svg" width="40" height="40"
                          viewBox="0 0 40 40" fill="none" :id="`pcr${value}`">
                          <rect width="40" height="40" fill="#E9E8E8"></rect>
                          <path :class="[`clleft`, getActiveAttribute(medical, value, {pcr: 'left'}) || `` ]"
                            d="M0 40L20 20L0 0V40Z" fill="#E9E8E8"></path>
                          <path :class="[`cltop`, getActiveAttribute(medical, value, {pcr: 'top'}) || `` ]"
                            d="M0 -1.74846e-06L20 20L40 0L0 -1.74846e-06Z" fill="#E9E8E8"></path>
                          <path :class="[`clright`, getActiveAttribute(medical, value, {pcr: 'right'}) || `` ]"
                            d="M40 0L20 20L40 40L40 0Z" fill="#E9E8E8"></path>
                          <path :class="[`clbottom`, getActiveAttribute(medical, value, {pcr: 'bottom'}) || `` ]"
                            d="M40 40L20 20L0 40L40 40Z" fill="#E9E8E8"></path>
                        </svg>
                      </td>
                    </tr>
                  </tbody>
                  <tfoot>
                    <tr>
                      <td class="text-bold" colspan="2">動揺度</td>
                      <td v-for="(value, index) in helpers.range(17, 32)" :key="index"
                        :class="[`item`, ( checkToothActive(medical.teeth, value)) ? `item-active` : ``]">
                        {{ showAgitation(medical.teeth, value) }}
                      </td>
                    </tr>
                    <tr>
                      <td class="text-bold" rowspan="2">EPP</td>
                      <td class="text-bold">頬側</td>
                      <td class="epp" rowspan="2" v-for="(value, index) in helpers.range(17, 32)" :key="index"
                        :id="`epp${value}`">
                        <table class="table-epp">
                          <tr>
                            <td :class="[`item`, ( checkToothActive(medical.teeth, value)) ? `item-active` : ``]">
                              {{ getActiveAttribute(medical, value, {buccal: ''})[1] || `-` }}</td>
                            <td :class="[`item`, ( checkToothActive(medical.teeth, value)) ? `item-active` : ``]">
                              {{ getActiveAttribute(medical, value, {buccal: ''})[2] || `-` }}</td>
                            <td :class="[`item`, ( checkToothActive(medical.teeth, value)) ? `item-active` : ``]">
                              {{ getActiveAttribute(medical, value, {buccal: ''})[3] || `-` }}</td>
                          </tr>
                          <tr>
                            <td :class="[`item`, ( checkToothActive(medical.teeth, value)) ? `item-active` : ``]">
                              {{ getActiveAttribute(medical, value, {lingual: ''})[1] || `-` }}</td>
                            <td :class="[`item`, ( checkToothActive(medical.teeth, value)) ? `item-active` : ``]">
                              {{ getActiveAttribute(medical, value, {lingual: ''})[2] || `-` }}</td>
                            <td :class="[`item`, ( checkToothActive(medical.teeth, value)) ? `item-active` : ``]">
                              {{ getActiveAttribute(medical, value, {lingual: ''})[3] || `-` }}</td>
                          </tr>
                        </table>
                      </td>
                    </tr>
                    <tr>
                      <td class="text-bold">舌側</td>
                    </tr>
                  </tfoot>
                </table>
              </div>

              <div class="blockData3D">
                <dl>
                  <dt>STL</dt>
                  <dd>
                    <div class="row list-galerry">
                      <div class="list-galerry-item list-galerry-itemstl"
                        v-for="(image, index) in medical.files.filter(item => ['upper', 'lower', 'front', 'whole'].includes(item.file_type))"
                        :key="index">
                        <UploadImage :is3d="true" :imageDefault="`/assets/images/stl.png`" v-bind:data="image"
                          :readonly="true" @uploadImage="selectImage(image)">
                        </UploadImage>
                      </div>
                    </div>
                  </dd>
                </dl>
              </div>

              <div class="fec mt20 btn-group-end">
                <!-- <ButtonUpdate></ButtonUpdate> -->
                <ButtonBack url="/medical-manage"></ButtonBack>
              </div>
            </div>
          </div>
        </div>
        <!-- -->
        <ToothModal v-if="medicalModals.showToothConfig"></ToothModal>

          <ImageModal v-if="medicalModals.showImage"></ImageModal>

        <!-- -->
      </div>
    </main>
  </div>
</template>

<script type="text/javascript">

import Teeth from "./components/Teeth"
import ToothModal from "./modals/ToothConfig"
import ImageModal from "./modals/Image"

import { computed } from "vue"
import { useRoute, useRouter } from "vue-router"
import { useStore } from "vuex"

export default {

  inject: ["helpers"],
  components: {
    Teeth,
    ToothModal,
    ImageModal
  },

  data() {
    return {
      isShow: true,
    }
  },

  setup() {
    const route = useRoute()
    const store = useStore()
   
    store.dispatch("attribute/fetchAll", {}).then(res => {
      let imageTypeAttribute = null
      if(res)
      {
        imageTypeAttribute = res.data.find(item => item.name === "image_type")
      }

      const payload = {
        id: route.params.id,
        imageTypeAttribute: imageTypeAttribute
      }
      store.dispatch("medical/fetch", payload)
      
    })


    function getActiveAttribute(medical, toothIndex, args) {
      let tooth = medical.teeth.find(item => item.tooth_index === toothIndex) || null
      if (tooth && args) {
        const key = Object.keys(args)[0]
        const value = args[key]

        if (key === "pcr") {
          let pcrValue = tooth.hasOwnProperty("pcr") ? tooth.pcr.value : ""

          if (pcrValue.includes(value)) {
            return `cl-red`
          }
        } else if (key === "buccal") {
          let buccalValue = tooth.hasOwnProperty("buccal") ? tooth.buccal.value : ""
          return buccalValue
        }
        else if (key === "lingual") {
          let lingual = tooth.hasOwnProperty("lingual") ? tooth.lingual.value : ""
          return lingual
        }
        return false
      }
      return false
    }

    return {
      medicalModals: computed(() => store.getters["medical/modals"]),
      medical: computed(() => store.getters["medical/medical"]),
      attributes: computed(() => store.getters["attribute/attributes"]),

      getActiveAttribute
    }
  },

  methods: {
    showPdf(pdf) {
      window.open(this.helpers.showPdf(pdf), '_blank');
    },

    checkToothActive(teethInfo, toothIndex) {
      let getTooth = teethInfo.find(item => item.tooth_index === toothIndex)
      if (typeof getTooth !== "undefined" && getTooth.pattern.value == "4") {
        return true
      }
      return false
    },

    showAgitation(teethInfo, toothIndex) {
      let getTooth = teethInfo.find(item => item.tooth_index === toothIndex)
      if (typeof getTooth !== "undefined" && getTooth.agitation) {
        return getTooth.agitation.value
      }
      return 0
    },

    showBleeding(teethInfo, toothIndex) {
      let getTooth = teethInfo.find(item => item.tooth_index === toothIndex)
      if (typeof getTooth !== "undefined" && getTooth.hasOwnProperty("bop") && getTooth.bop.value == "1") {
        return true
      }
      return false
    },

    showOrthodontist(orthodontists) {
      if (orthodontists && orthodontists.length) {

        return orthodontists.reduce((contain, item)=>{
          if(item.orthodontist){
            contain = [...contain, item.orthodontist.full_name];            
          }
          return contain;
        // }, []).join(", ");
        }, []);
      } else {
        return [];
      }
    },

    showImages() {
      this.isShow = !this.isShow
    },
    
  },

  beforeUnmount(){
    this.$store.dispatch("medical/resetMedical")
  }
}
</script>