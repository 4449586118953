<template>
  <div class="modal show" id="detailBox" tabindex="-1" role="dialog" data-id="">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">         
          <button class="close" type="button" aria-label="Close"  @click="modals.showModal('showToothConfig', !medicalModals.showToothConfig)">
            <span aria-hidden="true"> <i class="fa fa-times"></i></span>
          </button>
        </div>
        <div class="modal-body">
          <table class="table table-detailbox">
            <tbody>
              <tr v-if="toothInfo.hasOwnProperty('pattern')">
                <th>{{ toothInfo.pattern.attribute.display_name }}</th>
                <td>
                  <div class="fsc flex-wrap">
                    <label 
                    class="fsc mr30 check-custom"  
                    v-for="item, index in JSON.parse(toothInfo.pattern.attribute.meta)" 
                    > 
                      <input 
                      disabled="disabled" 
                      class="mr10" 
                      id="style1" 
                      type="checkbox" 
                      name="style1"
                      :checked="toothInfo.pattern.value === item.value" 
                      >
                      {{ item.label }}
                      <span class="checkmark"></span>
                    </label>
                  </div>
                </td>
              </tr>
              <tr v-if="toothInfo.hasOwnProperty('pcr')">
                <th>{{  toothInfo.pcr.attribute.display_name  }}</th>
                <td>
                  <div class="fsc position-custom">
                    <label :class="[`image-checkbox image-checkbox1 mr20`, toothInfo.pcr.value.includes('left') ? `image-checkbox-checked` : ``]" title="left" for="left">
                      <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect width="40" height="40" fill="#E9E8E8"></rect>
                        <path d="M20.5 20L0 0.5V39.5L20.5 20Z" fill="white"></path>
                      </svg>
                      <input type="checkbox" name="position" value="left" id="left">
                    </label>
                    <label :class="[`image-checkbox image-checkbox2 mr20`, toothInfo.pcr.value.includes('top') ? `image-checkbox-checked` : ``]" title="top" for="top">
                      <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
                        <rect width="40" height="40" fill="#E9E8E8"></rect>
                        <path d="M20.5 20.5L40 0L1 -1.70474e-06L20.5 20.5Z" fill="white"></path>
                      </svg>
                      <input type="checkbox" name="position" value="top" id="top">
                    </label>
                    <label :class="[`image-checkbox image-checkbox3 mr20`, toothInfo.pcr.value.includes('right') ? `image-checkbox-checked` : ``]" title="right" for="right">
                      <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
                        <rect width="40" height="40" fill="#E9E8E8"></rect>
                        <path d="M19 19.5L39.5 39L39.5 0L19 19.5Z" fill="white"></path>
                      </svg>
                      <input type="checkbox" name="position" value="right">
                    </label>
                    <label :class="[`image-checkbox image-checkbox4`, toothInfo.pcr.value.includes('bottom') ? `image-checkbox-checked` : ``]" title="bottom" for="bottom">
                      <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
                        <rect width="40" height="40" fill="#E9E8E8"></rect>
                        <path d="M20.5 19L1 39.5L40 39.5L20.5 19Z" fill="white"></path>
                      </svg>
                      <input type="checkbox" name="position" value="bottom">
                    </label>
                  </div>
                </td>
              </tr>
              <tr  v-if="toothInfo.hasOwnProperty('bop')">
                <th>{{ toothInfo.bop.attribute.display_name }}</th>
                <td>
                  <div class="fsc">

                    <label 
                    class="fsc mr30 check-custom gray-check" 
                    v-for="item, index in JSON.parse(toothInfo.bop.attribute.meta)"
                    >
                      <input 
                      class="mr10" 
                      disabled="disabled" 
                      type="checkbox" 
                      name="check1" 
                      :checked="toothInfo.bop.value === item.value">{{ item.label }}
                      <span class="checkmark"></span>
                    </label>

                  </div>
                </td>
              </tr>

              <tr  v-if="toothInfo.hasOwnProperty('agitation')">
                <th>{{ toothInfo.agitation.attribute.display_name }}</th>
                <td>
                  <div class="fsc">
                      <input 
                      class="form-control mr10" 
                      disabled="disabled" 
                      type="text" 
                      name="check1" 
                      :value="toothInfo.agitation.value"
                      >
                  </div>
                </td>
              </tr>
      
      
              <tr v-if="toothInfo.hasOwnProperty('buccal')">
                <th>EPP</th>
                <td>
                  <div class="fsc jaw-box">
                    <label id="jawtag1">{{ toothInfo.buccal.attribute.display_name }}</label>
                    <div 
                    class="select-item-wrapper mr10"  
                    v-for="item, index in JSON.parse(toothInfo.buccal.attribute.meta)" >
                      <select class="form-select select-item form-control" id="select1" name="cheek" disabled="disabled" >
                        <option v-for="value, i in item.options"  :selected="toothInfo.buccal.value[index+1] == value" >{{ value }}</option>
                      </select>
                    </div>
                  </div>
                  <div class="fsc mt10 jaw-box">
                    <label id="jawtag2">{{ toothInfo.lingual.attribute.display_name }}</label>
                    <div 
                    class="select-item-wrapper mr10"  
                    v-for="item, index in JSON.parse(toothInfo.lingual.attribute.meta)" >
                      <select class="form-select select-item form-control" id="select1" name="cheek" disabled="disabled" >
                        <option v-for="value, i in item.options"  :selected="toothInfo.lingual.value[index+1] == value" >{{ value }}</option>
                      </select>
                    </div>
                  </div>
                </td>
              </tr>


            </tbody>
          </table>
        </div>
   <!--      <div class="modal-footer">
          <a class="btn btn-primary confirmChange close" id="confirmChange" type="button" data-bs-dismiss="modal" aria-label="Close">
            登録
          </a>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script type="text/javascript">
import { useStore } from "vuex"
import { defineComponent, inject } from "vue"

export default defineComponent({

  props: {
    isShow: [Boolean],
    medical: {
      type: Object
    }
  },

  setup(props) {
    const modals = inject('modals')
    const store = useStore() 
    const medicalModals = store.getters["medical/modals"]
    const toothInfo = store.getters["medical/toothInfo"]

    return {
      toothInfo,

      modals,
      medicalModals
    }
  },

})
</script>