<template>
  <section class="main_body p-login">
    <div id="layoutSidenav">
      <div id="layoutSidenav_content">
        <main>
          <div class="container-fluid">
            <div class="login-top">
              <div class="item logo">
                <img 
                src="/assets/images/liex-04.png" height="30"
                alt="Liex"
                />
              </div>
<!--               <div class="item green-info"><span>ご登録いただいたメールアドレス宛に、パスワード再設定用のURLリンクを送信しました。</span></div> -->
            </div>


            <!-- <div class="login-bottom"> -->
              <div class="loginbox_c fz16">
                <div class="logobox">
                  <div class="text-center">
                    <img 
                    src="/assets/common_img/liex-03.png" height="220"
                    alt="Liex"
                    />
                  </div>

                  <p style="text-align: center; color: #3fb409; font-weight: bold;">症例相談・共有</p>
                </div>
                <Form 
                class="formlogin"
                :validation-schema="schema"
                @submit="onSubmit" 
                :initial-values="formValues"
                >
                  <div class="formbox_e">
                    <Field
                      name="id"
                      class="form-control"
                      placeholder="ログインID"
                      maxlength="50"
                      type="text"
                    />
                    <ErrorMessage 
                    class="error"
                    name="id"
                    />
                  </div>
                  <div class="formbox_e">
                    <div class="fsc boxpassword">
                      <Field
                        class="form-control pass_log_id"

                        :type="typePassword"
                        maxlength="15"
                        name="password"
                        placeholder="パスワード"
                      />
                      <span  @click="changeTypePassword()">
                        <i 
                        v-if="typePassword === `password`"
                        :class="[`fa toggle-password fa-eye`]"
                        />
                        <i 
                        v-else
                         :class="[`fa toggle-password fa-eye-slash`]"
                        />
                      </span>
                    </div>
                    <ErrorMessage
                    class="error"
                    name="password"
                    />
                  </div>
                  <div class="forbox_e  text-center">
                    <label style="font-weight: normal; display: flex; margin: 10px 0; align-items: center;">
                      <input type="checkbox"  v-model="remindLogin"  >
                      <span style="padding-left: 10px;">ログイン情報を保存する</span>
                    </label>
                    
                    <button type="submit" class="btn btn-large btn-primary btn-primary-2">
                      ログイン
                    </button>
                  </div>
                  <div class="formbox_e mt20 text-center">
                    <RouterLink :to="{name: `ForgotIndex`}">パスワードをお忘れの方 </RouterLink>
                  </div>
                </Form>
              </div>
            <!-- </div> -->
          </div>
        </main>
      </div>
    </div>
  </section>
</template>

<script type="text/javascript">
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import schemaLogin from "@/schema/auth/login"
import { defineComponent, watch, computed, ref } from 'vue'


export default defineComponent({

  data() {
    return {
      typePassword: "password",
    }
  },

  setup(props) {
    const store = useStore()
    const router = useRouter()
    const formValues = {
      id: "",
      password: "",
    }

    const schema = schemaLogin;
    let remindLogin = ref(store.getters["auth/remindLogin"])
    watch(remindLogin, function (val) {
      remindLogin = val
    })

    function onSubmit(data) {
      data.remindLogin = remindLogin
      store.dispatch("auth/login", data).then(res => {
        if(res === true) {
          router.push("/profile")
        }
      })
    }



    if(remindLogin.value === true) {
      let remindData = JSON.parse(localStorage.getItem("remindData"))
      if(remindData) {
        formValues.id = remindData.id
        formValues.password = remindData.password
      }
    }


    return {
      formValues,
      schema,
      onSubmit,
      remindLogin
    };
  },

  methods: {
    changeTypePassword() {
      this.typePassword = this.typePassword === `password` ? `text` : `password`
    }
  },


});
</script>
