import langs from "@/plugins/lang"
import ProfileService from "@/services/profile"
import toastPlugin from "@/plugins/toast"

export  const getters = {
  info: state => state.info
};

export const getDefaultState = () => ({
  info: null,
})

export const state = getDefaultState

export  const actions = {

  async fetch({commit}, payload) {
    const res = await ProfileService.fetch()
    if(res.status === 200) {
      commit("SET_INFO", res.data.data)
    }
  },

  async update({commit}, payload) {

    const data = {
      full_name: payload.fullName,
      username: payload.username,
    }

    if(payload.password) {
      data.password = payload.password 
    }


    const res = await ProfileService.update(data)
    if(res.status === 200) {
      toastPlugin.success(res.data.message ? res.data.message : lang.UPDATE_PROFILE_SUCCEED)
      commit("SET_INFO", res.data.data)
    }
  },

  reset({commit}) {
    commit('RESET')
  }
 }

export  const mutations = {

  SET_INFO(state, data) {
    data.password = ""
    state.info = data
  },

  RESET(state) {
    Object.assign(state, getDefaultState())
  }
}


export default {
  namespaced: true,
  getters,
  state,
  actions,
  mutations
}
