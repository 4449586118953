<template>
     <!-- HEADER -->
    <nav class="sb-topnav navbar navbar-expand navbar-light bg-white" v-if="userInfo">
      <!--  Navbar Brand-->
      <router-link class="navbar-brand" to="/medical-manage">
         <img height="30" src="/assets/common_img/logo_side.png" alt="Liex〇〇デンタルクリニック様"><span>{{ userInfo.full_name }}デンタルクリニック様</span>
      </router-link>

      <!--  Sidebar Toggle-->
      <button 
      class="btn btn-link btn-sm order-1 order-lg-0 me-lg-0" 
      id="sidebarToggle" href="javascript:void(0)"
       @click="modals.showMenu('showMenu', !showMenu)"
      >
        <i class="fa fa-bars"></i>
      </button>
      <!--  Navbar Search-->


      <!--  Navbar-->
    </nav>
    <!-- /END HEADER -->
</template>

<script type="text/javascript">
import { useStore } from "vuex"
import { computed, inject } from "vue"

export default {
  setup() {
    const store = useStore()
    const modals = inject('modals')

    return {
      userInfo: computed(() => store.getters["profile/info"]),
      showMenu: computed(() => store.getters["component/showMenu"]),
      modals,
    }
  }
}
</script>
