<template>
  <div id="layoutSidenav_content">
    <main>
      <div class="container-fluid">
        <div class="card">
          <div class="card-header">アカウント管理-編集</div>
          <div class="card-body">           
            <div class="dataTable-container" v-if="userInfo">
              <Form 
                class="formlogin"
                :validation-schema="schema"
                @submit="onSubmit" 
                >    
                <table class="table table_detail">   
                    <tbody>
                      <tr>
                        <td>アカウント名</td>
                        <td>
                          <div class="fsc">

                            <Field
                              name="fullName"
                              class="form-control"
                              placeholder="●●●歯科"
                              maxlength="30"
                              type="text"
                              v-model="userInfo.full_name"
                            />
                          </div>
                          <ErrorMessage 
                            class="error"
                            name="fullName"
                            />
                        </td>
                      </tr>
                      <tr>
                        <td>ID</td>
                        <td>
                          <div class="fsc">
                            <Field
                              name="username"
                              class="form-control"
                              placeholder="Sakaiclinic"
                              maxlength="30"
                              type="text"
                              v-model="userInfo.username"
                            />              
                          </div>
                          <ErrorMessage 
                            class="error"
                            name="username"
                          />   
                        </td>
                      </tr>
                      <tr>
                        <td>PW</td>
                        <td>
                          <div class="fsc boxpassword">
                            <Field  
                            name="password"
                            class="form-control pass_log_id"
                            :type="typePassword"
                            maxlength="15" 
                             /> 
                            <span @click="changeTypePassword()">
                               <i 
                              v-if="typePassword === `password`"
                              :class="[`fa toggle-password fa-eye`]"
                              />
                              <i 
                              v-else
                               :class="[`fa toggle-password fa-eye-slash`]"
                              />
                            </span>    

                          </div>
                          <ErrorMessage
                            class="error"
                            name="password"
                            />  
                        </td>
                      </tr>
                    </tbody>
                </table>

                <div class="fec mt20 btn-group-end">
                  <ButtonUpdate></ButtonUpdate>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<script type="text/javascript">

import { computed } from 'vue'
import { useStore } from "vuex"
import schemaProfile from "@/schema/profile"

export default {

  setup() {
    const store = useStore()
    const schema = schemaProfile

    function onSubmit(data) {
      store.dispatch("profile/update", data)
    }

    return {
      onSubmit,
      schema,
      userInfo: computed(() => store.getters["profile/info"]),

    }
  },

  created() {
    const payload = {}
    this.$store.dispatch("profile/fetch", payload)
  },

  data() {
    return {
      typePassword: "password"
    }
  },

  methods: {
    changeTypePassword() {
      this.typePassword = this.typePassword === `password` ? `text` : `password`
    }
  },

}

</script>