<template>

  <a class="btn btn-primary" @click="$emit('submit')">
  	<button style="color: white; background-color: transparent;">登録</button>
  </a>
</template>

<script type="text/javascript">
export default {

}
</script>
