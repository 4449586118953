import { createApp } from 'vue'
import App from './App.vue'
import Router from './router'
import Store from "./store"

// lib components
import { Form, Field, ErrorMessage } from 'vee-validate';

// import "@google/model-viewer/dist/model-viewer";

import Datepicker from "vue3-date-time-picker"
import 'vue3-date-time-picker/dist/main.css'

// global components
import UploadImage from "@/components/UploadImage"
import ButtonBack from "@/components/button/Back"
import ButtonCloseModal from "@/components/button/CloseModal"
import ButtonUpdate from "@/components/button/Update"
import ButtonCopy from "@/components/button/Copy"
import ButtonView from "@/components/button/View"
import ButtonRemove from "@/components/button/Remove"

import AuthLayout from "@/views/layouts/Auth"
import MainLayout from "@/views/layouts/Main"

// helpers
import helperPlugin from "@/plugins/helper"

//mixins
import modalPlugin from "@/plugins/modal"

import langPlugin from "@/plugins/lang"

const app = createApp(App)
app.use(Router)
app.use(Store)



app.provide('helpers',  helperPlugin)
app.provide('langs', langPlugin)
app.provide('modals', modalPlugin)


app.component('auth', AuthLayout)
app.component('main', MainLayout)


app.component('DatePicker', Datepicker)
app.component('Form', Form)
app.component('Field', Field)
app.component('ErrorMessage', ErrorMessage)

app.component('UploadImage', UploadImage)
app.component('ButtonBack', ButtonBack)
app.component('ButtonCloseModal', ButtonCloseModal)
app.component('ButtonUpdate', ButtonUpdate)
app.component('ButtonCopy', ButtonCopy)
app.component('ButtonView', ButtonView)
app.component('ButtonRemove', ButtonRemove)

app.mount('#app')