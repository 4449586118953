import { createRouter, createWebHistory } from 'vue-router'
import { useCookies } from 'vue3-cookies'

import ProfileSerivce from '@/services/profile'

import Login from '@/views/pages/auth/Login'
import Logout from '@/views/pages/auth/Logout'

import Forgot from '@/views/pages/auth/forgot/Forgot'
import ForgotIndex from '@/views/pages/auth/forgot/Index'
import ForgotOtp from '@/views/pages/auth/forgot/Otp'
import ResetPass from '@/views/pages/auth/forgot/ResetPass'

import MedicalManage from '@/views/pages/medical/MedicalManage'
import MedicalManageIndex from '@/views/pages/medical/Index'
import MedicalManageDetail from '@/views/pages/medical/Detail'


import ProfileManage from '@/views/pages/profile/ProfileManage'
import ProfileManageDetail from '@/views/pages/profile/Detail'



const routes = [

  {
    path: '/',
    redirect: '/login'
  },

  {
    path: '/login',
    component: Login,
    meta: { layout: 'auth' },
    name: "Login"
  },

  {
    path: '/forgot',
    component: Forgot,
    meta: {
      layout: 'auth'
    },
    children: [
      {
        path: '',
        component: ForgotIndex,
        name: 'ForgotIndex'
      },
      {
        path: 'otp',
        component: ForgotOtp,
        name: 'ForgotOtp'
      },
      {
        path: 'reset',
        component: ResetPass,
        name: 'ResetPass'
      }
    ]
  },

  {
    path: '/logout',
    component: Logout, 
    meta: { layout: 'empty' },
  },

  {
    path: '/medical-manage',
    component: MedicalManage,
    meta: { 
      layout: 'main',
      requiresAuth: true 
    },
    children: [
      {
        path: '',
        component: MedicalManageIndex,
        name: "MedicalManageIndex"
      },
      {
        path: 'detail/:id',
        component: MedicalManageDetail,
        name: "MedicalManageDetail"
      }
    ]
  },

  {
    path: '/profile',
    component: ProfileManage,
    meta: { 
      layout: 'main',
      requiresAuth: true 
    },
    children: [
      {
        path: '',
        component: ProfileManageDetail,
        name: "profileDetail"
      },
    ]
  },
]


const router = createRouter({
  history: createWebHistory(),
  routes: routes,
  linkActiveClass: "active",
  scrollBehavior () {
    return { top: 0 }
  }
})

router.beforeEach(async (to, from) => {

  const { cookies } = useCookies()
  const accessToken = cookies.get("accessToken")


  if(to.meta.requiresAuth) {
    if(accessToken) {
      const res = await ProfileSerivce.fetch()
      if(res.status === 200) {
       
      }else {

      }
    }else {
      return {
        path: '/login',
        query: { redirect: to.fullPath },
      }
    }
  } 

 



})


export default router