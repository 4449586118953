import langs from "@/plugins/lang"

export  const getters = {
  showOverlay: state => state.showOverlay,
  showMenu: state => state.showMenu
};

export const getDefaultState = () => ({
  showOverlay: false,
  showMenu: false,
})

export const state = getDefaultState

export  const actions = {

  reset({commit}) {
    commit('RESET')
  }
 }

export  const mutations = {

  SHOW_OVERLAY(state, data) {
    state.showOverlay = data
  },
  SHOW_MENU(state, data) {
    state.showMenu = data;
  },

  RESET(state) {
    Object.assign(state, getDefaultState())
  }
}


export default {
    namespaced: true,
    getters,
    state,
    actions,
    mutations
}
