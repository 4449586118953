<template>
   <section class="main_body p-account_management">
      <div id="layoutSidenav">
        <Sidebar />

        <router-view></router-view>
      </div>
    </section>
</template>


<script type="text/javascript">
import Sidebar from "@/views/layouts/partials/Sidebar"

export default {
  components: { 
    Sidebar,

  },
}
</script>
