import langs from "@/plugins/lang"
import helpers from '@/plugins/helper'
import MedicalService from "@/services/medical"
import toastPlugin from "@/plugins/toast"

export  const getters = {
  modals: state => state.modals,
  medicals: state => state.medicals,
  medical: state => state.medical,

  toothInfo: state => state.toothInfo,
  imageSelected: state => state.imageSelected,
  visibleSelected: state => state.visibleSelected
};

export const getDefaultState = () => ({
  modals: {
    showToothConfig: false,
    showImage: false,
  },
  medicals: {
    data: [],
    currentPage: 1,
    lastPage: 0,
    itemPerPage: 10,
    total: 0,
  },
  medical: null,
  toothInfo: null,
  
  imageSelected: null,
  visibleSelected: null

})

export const state = getDefaultState

export  const actions = {

  async fetchAll({commit}, payload) {
    const params = payload

    if(params.type.length && params.type.includes("PCR")) {
      params.pcr = true
    }

    if(params.type.length && params.type.includes("画像")) {
      params.images = true
    }

    if(params.type.length && params.type.includes("STL")) {
      params.files = true
    }
    delete params.type
    const res = await MedicalService.fetchAll(params)
    if(res.status === 200) {
      commit("FETCH_ALL", res.data.data)
    }
  },

  async fetch({commit}, payload) {
    const { id, imageTypeAttribute } = payload
    const res = await MedicalService.fetch(id)
    if(res.status === 200) {
      commit("FETCH", {
        data: res.data.data,
        imageTypeAttribute: imageTypeAttribute
      })
      return true
    }

    const listErr = helpers.showListError(res.status, res.data)   
    toastPlugin.error(listErr)
  },

  async update({commit}, payload) {

  },

  resetMedical({commit})
  {
    commit("RESET_MEDICAL")
  },

  reset({commit}) {
    commit('RESET')
  }
 }

export  const mutations = {

  FETCH_ALL(state, data) {
    const medicals = state.medicals
    medicals.currentPage = data.current_page
    medicals.data = data.data
    medicals.lastPage = data.last_page
    medicals.total = data.total
    medicals.itemPerPage = data.per_page
    state.medicals = medicals
  },

  FETCH(state, data) {
    let medical = data.data
    let imageTypeAttribute = data.imageTypeAttribute

    if(imageTypeAttribute) 
    {
      let metas = JSON.parse(imageTypeAttribute.meta)
      let images = medical.images
      let arrImages = []
      metas.forEach(meta => {
        images.forEach(image => {
          if(meta.name == image.image_type)
          {
            arrImages.push(image)
          }
        })
      })
      medical.images = arrImages
    }
    state.medical = medical
  },

  FETCH_TOOTH(state, data) {
    state.toothInfo = data
  },

  SHOW_MODAL(state, data) {
    const {key, val} = data
    const modals = state.modals

    modals[key] = val
    state.modals = modals
  },

  SELECTED_IMAGE(state, data) {
    state.imageSelected = data
  },

  UPDATE_VISIBLE(state, data) {
    state.visibleSelected = data
  },

  RESET_MEDICAL(state) {
    state.medical = null
  },

  RESET(state) {
    Object.assign(state, getDefaultState())
  }
}


export default {
    namespaced: true,
    getters,
    state,
    actions,
    mutations
}
