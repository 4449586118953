<template>
   <svg xmlns="http://www.w3.org/2000/svg" width="300" height="500" viewBox="0 0 370 555" fill="none">

      <ToothComponent v-for="value, index in helpers.range(1, 32)" :key="index" :toothIndex="value" :teethInfo="teeth"></ToothComponent>

      <defs>
        <clipPath id="clip0_92_2">
          <rect width="55" height="43" fill="white" transform="translate(0 229)"></rect>
        </clipPath>
        <clipPath id="clip1_92_2">
          <rect width="58" height="53" fill="white" transform="translate(0 179)"></rect>
        </clipPath>
        <clipPath id="clip2_92_2">
          <rect width="66" height="61" fill="white" transform="translate(5 122)"></rect>
        </clipPath>
        <clipPath id="clip3_92_2">
          <rect width="58" height="62" fill="white" transform="translate(9 122)"></rect>
        </clipPath>
        <clipPath id="clip4_92_2">
          <rect width="50" height="46" fill="white" transform="translate(30 88)"></rect>
        </clipPath>
        <clipPath id="clip5_92_2">
          <rect width="46" height="45" fill="white" transform="translate(52 56)"></rect>
        </clipPath>
        <clipPath id="clip6_92_2">
          <rect width="46" height="48" fill="white" transform="translate(78 29)"></rect>
        </clipPath>
        <clipPath id="clip7_92_2">
          <rect width="38" height="47" fill="white" transform="translate(109 10)"></rect>
        </clipPath>
        <clipPath id="clip8_92_2">
          <rect width="43" height="53" fill="white" transform="translate(142)"></rect>
        </clipPath>
        <clipPath id="clip9_92_2">
          <rect width="55" height="43" fill="white" transform="matrix(-1 0 0 1 370 229)"></rect>
        </clipPath>
        <clipPath id="clip10_92_2">
          <rect width="58" height="53" fill="white" transform="matrix(-1 0 0 1 370 179)"></rect>
        </clipPath>
        <clipPath id="clip11_92_2">
          <rect width="66" height="61" fill="white" transform="matrix(-1 0 0 1 365 122)"></rect>
        </clipPath>
        <clipPath id="clip12_92_2">
          <rect width="58" height="62" fill="white" transform="matrix(-1 0 0 1 361 122)"></rect>
        </clipPath>
        <clipPath id="clip13_92_2">
          <rect width="50" height="46" fill="white" transform="matrix(-1 0 0 1 340 88)"></rect>
        </clipPath>
        <clipPath id="clip14_92_2">
          <rect width="46" height="45" fill="white" transform="matrix(-1 0 0 1 318 56)"></rect>
        </clipPath>
        <clipPath id="clip15_92_2">
          <rect width="46" height="48" fill="white" transform="matrix(-1 0 0 1 292 29)"></rect>
        </clipPath>
        <clipPath id="clip16_92_2">
          <rect width="38" height="47" fill="white" transform="matrix(-1 0 0 1 261 10)"></rect>
        </clipPath>
        <clipPath id="clip17_92_2">
          <rect width="43" height="53" fill="white" transform="matrix(-1 0 0 1 228 0)"></rect>
        </clipPath>
        <clipPath id="clip18_92_2">
          <rect width="57" height="45" fill="white" transform="translate(3 314)"></rect>
        </clipPath>
        <clipPath id="clip19_92_2">
          <rect width="58" height="45" fill="white" transform="translate(2 314)"></rect>
        </clipPath>
        <clipPath id="clip20_92_2">
          <rect width="57" height="45" fill="white" transform="matrix(-1 0 0 1 366 314)"></rect>
        </clipPath>
        <clipPath id="clip21_92_2">
          <rect width="58" height="45" fill="white" transform="matrix(-1 0 0 1 367 314)"></rect>
        </clipPath>
        <clipPath id="clip22_92_2">
          <rect width="58" height="53" fill="white" transform="translate(5 356)"></rect>
        </clipPath>
        <clipPath id="clip23_92_2">
          <rect width="58" height="53" fill="white" transform="matrix(-1 0 0 1 364 356)"></rect>
        </clipPath>
        <clipPath id="clip24_92_2">
          <rect width="62" height="63" fill="white" transform="translate(24 404)"></rect>
        </clipPath>
        <clipPath id="clip25_92_2">
          <rect width="62" height="63" fill="white" transform="matrix(-1 0 0 1 345 404)"></rect>
        </clipPath>
        <clipPath id="clip26_92_2">
          <rect width="46" height="43" fill="white" transform="translate(55 456)"></rect>
        </clipPath>
        <clipPath id="clip27_92_2">
          <rect width="46" height="43" fill="white" transform="matrix(-1 0 0 1 314 456)"></rect>
        </clipPath>
        <clipPath id="clip28_92_2">
          <rect width="43" height="42" fill="white" transform="translate(75 483)"></rect>
        </clipPath>
        <clipPath id="clip29_92_2">
          <rect width="43" height="42" fill="white" transform="matrix(-1 0 0 1 294 483)"></rect>
        </clipPath>
        <clipPath id="clip30_92_2">
          <rect width="37" height="47" fill="white" transform="translate(105 495)"></rect>
        </clipPath>
        <clipPath id="clip31_92_2">
          <rect width="37" height="47" fill="white" transform="matrix(-1 0 0 1 264 495)"></rect>
        </clipPath>
        <clipPath id="clip32_92_2">
          <rect width="33" height="51" fill="white" transform="translate(132 504)"></rect>
        </clipPath>
        <clipPath id="clip33_92_2">
          <rect width="33" height="51" fill="white" transform="matrix(-1 0 0 1 237 504)"></rect>
        </clipPath>
        <clipPath id="clip34_92_2">
          <rect width="25" height="46" fill="white" transform="translate(160 509)"></rect>
        </clipPath>
        <clipPath id="clip35_92_2">
          <rect width="25" height="46" fill="white" transform="matrix(-1 0 0 1 209 509)"></rect>
        </clipPath>
      </defs>
    </svg>
</template>

<script type="text/javascript">

import ToothComponent from "@/components/Tooth"

export default {
  components: {
    ToothComponent
  },

  inject: ['modals', 'helpers'],
  props: {
    teeth: {
      required: true,
      type: Object
    }
  },

  setup(props) {
    const { teeth } = props
  },



}
</script>