export default {

  defaultString(val) {
    return !val ? "" : val;
  },

  defaultNumber(val) {
    return !val ? 0 : parseInt(val);
  },

  getYear(date){
    return new Date(date).getFullYear();
  },

  getMonth(date){
    return new Date(date).getMonth() + 1;
  },

  getDay(date){
    return new Date(date).getDate();
  },

  getHours(date){
    return new Date(date).getHours();
  },


  getMinutes(date){
    return new Date(date).getMinutes();
  },


   /*
    @input: date: yyyy-mm-dd
    @output: {
       date: yyyy-mm-dd 00:00:00
    }
  */
  convertDateToTz(date){
    var d = new Date(date); 
    return new Date(d.getTime() - (d.getTimezoneOffset() * 60000)).toISOString();
  },

  /*
    @input: date: yyyy-mm-dd
    @output: {
       date: yyyy/mm/dd
    }
  */
  formatDate(date) {
    if (!date) return null;

    const [year, month, day] = date.split("-");
    return `${year}/${month}/${day}`;
  },
  /*
    @input: date: yyyy/mm/dd
    @output: {
       date: yyyy-mm-dd
    }
  */
  parseDate(date) {
    if (!date) return null;

    const [month, day, year] = date.split("/");
    return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
  },

  /*
    @input: datetime
    @output: {
       date: yyyy/mm/dd
    }
  */
  convertTzTo(date, showTime = false, prefix = '/'){

    if(date){
      var d = new Date(date);
      var   month = '' + (d.getMonth() + 1);
      var   day = '' + d.getDate();
      var   year = d.getFullYear();
      var   hour = d.getHours().toString();
      var   min = d.getMinutes().toString();
      var   milis = "00";
      if (month.length < 2) 
         month = '0' + month;
      if (day.length < 2) 
          day = '0' + day;

      if (hour.length < 2) 
         hour = '0' + hour;
      if (min.length < 2) 
          min = '0' + min;

      return !showTime ? [year, month, day].join(prefix) : [year, month, day].join(prefix) + " " + [hour, min, milis].join(':')
    }  
    return "";
  },

  /*
    @input: Number, Number
    @output: {
      offset: Number
    }
  */
  calcPagination(currentPage, itemsPerPage){
    var offset;
    if (currentPage == 1) {
        offset = 0;
    } else {
        offset = (currentPage - 1) * itemsPerPage;
    }
    return offset
  },


  /*
    @input: Number, Number, Number
    @output: {
      offset: Number
    }
  */
  showIndex(index, page, itemsPerPage) {

    index++
    if(page === 1){
      return index
    }

    return index + ( (page - 1) * itemsPerPage)
  },

  /*
    @input: error: Array
    @output: JSON
  */
  getError(error) {
    const errors = error.response
    return errors
  },

  /*
    @input: ArrayList
    @output: Array
  */
  getLastItem(array){
    return array[array.length - 1]
  },


  /*
    @input: String
    @output: Split String 
  */
  splitString(val, length){
    if(val.length > length){
      return val.substring(0, length).concat("...");
    }
    return val;
  },

  range(start, end) {
    return Array(end - start + 1).fill().map((_, idx) => start + idx)
  },

  formatBirthday(data, ex) {

    const {year, month, day} = {...data}
    return  year + ex + String(month).padStart(2, '0') + ex + String(day).padStart(2, '0')
  },

  showListError(status, data) {
    let listErr = ""
    let {message} = data
    switch(status) {
      case 422: 
        let {details} = data

        for(var field in details) {
          if(details[field].length) {
            for(var error of details[field]) {
              listErr += error
            }
          }
        } 
        break
      case 403: 
        listErr += message
        break
      default: 
        listErr += message
    }
    return listErr
  },

  showGender(gender) {
     switch(gender) {
        case "male":
         return "男性"
        case "female":
          return "女性"
        case "other":
          return "その他"
        default:   
     }
  },

  randomString(length) {
      var result           = '';
      var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      var charactersLength = characters.length;
      for ( var i = 0; i < length; i++ ) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
      }
      return result;
  },

  validateImage(file) {
    const fileName = file.name
    if(!(/\.(jpe?g|png|)$/i).test(fileName)) {
      return "画像種類のみ"
    }
    return true
  },

  showImageURL(srcImage) {
    // if(!srcImage || srcImage === `undefined` || !srcImage.match(/.(jpg|jpeg|png|gif|jfif)$/i)) {
    if(!srcImage || srcImage === `undefined`) {
      return null
    }
    return srcImage
  },

  show3D(srcFile) {
    console.log(srcFile)
    if(!srcFile || srcFile === `undefined`) {
      return null
    }
    return srcFile
  },

  showPdf(src) {
    if(!src || src === `undefined` || !src.match(/.(pdf)$/i))
      return null

    return src
  },

  /*
    number: String, Number
  */
  formatNumber(number) {
    if(!number || number === null) {
      return 0
    }
    number = parseInt(number)

    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
  },

  findDuplicateValue(arr) {
    let sortedArr = arr.slice().sort()

    let results = [];
    for (let i = 0; i < sortedArr.length - 1; i++) {
      if (sortedArr[i + 1] == sortedArr[i]) {
        results.push(sortedArr[i])
      }
    }
    return results
  },

  calcTimeBefore(dateNow, dateBefore) {

    const years = dateNow.getFullYear() - dateBefore.getFullYear()
    const months = dateNow.getMonth() - dateBefore.getMonth()
    const days = dateNow.getDate() - dateBefore.getDate()
    const hours = dateNow.getHours() - dateBefore.getHours()
    const minutes = dateNow.getMinutes() - dateBefore.getMinutes()

    if(years > 0) {
      return `${years}年前`
    }

    if(months > 0) {
      return `${months}月前`
    }

    if(days > 0) {
      return `${days}日前`
    }

    if(hours > 0) {
      return `${hours}時前`
    }

    if(minutes > 0) {
      return `${minutes}分前`
    }
  },


  calcTimeAfter(dateNow, dateAfter) {

    const years = dateAfter.getFullYear() - dateNow.getFullYear()
    const months = dateAfter.getMonth() - dateNow.getMonth()
    const days = dateAfter.getDate() - dateNow.getDate()
    const hours = dateAfter.getHours() - dateNow.getHours()
    const minutes = dateAfter.getMinutes() - dateNow.getMinutes()

    if(years > 0) {
      return `${years}年前`
    }

    if(months > 0) {
      return `${months}月前`
    }

    if(days > 0) {
      return `${days}日前`
    }

    if(hours > 0) {
      return `${hours}時前`
    }

    if(minutes > 0) {
      return `${minutes}分前`
    }
  },

  isMobile() {
    if( /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ) {
      return true
    }
    return false
  },

  detectDevice() {

    // 1. IOS 
    // 2. Android
    // 3. Desktop

    let isIOS =  [
      'iPad Simulator',
      'iPhone Simulator',
      'iPod Simulator',
      'iPad',
      'iPhone',
      'iPod'
    ].includes(navigator.platform) || (navigator.userAgent.includes("Mac") && "ontouchend" in document)

    let isAndroid = /(android)/i.test(navigator.userAgent)

    if(isIOS) {
      return 1
    }else if(isAndroid) {
      return 2
    }else {
      return 3
    }
  },

}