<template>
  <section class="main_body p-login p-forgetpass">
    <div id="layoutSidenav">
      <div id="layoutSidenav_content">
        <main>
          <div class="container-fluid">
            <div class="login-top">
              <div class="item logo">
                  <img 
                src="/assets/images/logo.png"
                alt="Liex"
                />
              </div>
            </div>

            <div class="login-bottom" >

              <router-view></router-view>

            </div>
          </div>
        </main>
      </div>
    </div>
  </section>
</template>
