<template>
  <section class="main_body p-medical_management"> 
    <div id="layoutSidenav">
      <Sidebar />

      <router-view></router-view>

<!--        <model-viewer
            src="https://liex-admin.gonosen.work/storage/upload/medicalRecords/api/file/3d/20220510160401/upper.glb"
            poster="https://cdn.glitch.com/36cb8393-65c6-408d-a538-055ada20431b%2Fposter-astronaut.png?v=1599079951717"
            alt="A 3D model of an astronaut"
            shadow-intensity="1"
            camera-controls
            auto-rotate
        ></model-viewer> -->

    </div>
  </section>
</template>
<style lang="scss">
.p-medical_management {
  .datepick {
    .date {
      width: 220px;
    }

    .dp__input_icon_pad {
      padding-left: 40px;
    }
  }
} 
</style>

<script type="text/javascript">
import Sidebar from "@/views/layouts/partials/Sidebar"

export default {
  components: { 
    Sidebar,
  },
}
</script>
