<template>
  <figure>
    <object :data="imagePath || (imageDefault || `/assets/images/img.png`)" :alt="data.title"></object>
  </figure>
  <label class="btn btn-outline-primary" @click="openDialog()" ><span>{{ data.title.length < 10 ? data.title : (data.title.slice(0, 10) + '...')}}</span></label>
  <input :disabled="readonly" type="file" accept="image/*" ref="file" @change="selectImage($event)" > 
</template>

<script type="text/javascript">
import { inject, ref  } from 'vue'
import { useStore } from "vuex"

export default {

  emits: ["uploadImage"],
  props: {
    defaultImage: {
      type: String
    },
    data: {
      type: [Object, String]
    },
    readonly: Boolean,
    imageDefault: String,
    is3d: Boolean
  },

  setup(props, {emit}) {
    const store = useStore()
    const modals = inject('modals')
    let helpers = inject('helpers')
    let getData = props.data
    let imagePath = helpers.showImageURL(props.data.path) 
    const medicalModals = store.getters["medical/modals"]

    function selectImage(event) { 
      try {
        const file = event.target.files[0]
        const validate = helpers.validateImage(file)
        if(validate === true) {
          getData.path = file
          imagePath = URL.createObjectURL(file)
          getData.file = file
        }


        emit('uploadImage', getData)

      }catch(error) {
        console.log(error)
      }
    }

    return {
      getData,
      helpers,
      imagePath,

      selectImage,
      store,
      modals,
      medicalModals,
      medical: store.getters["medical/medical"]
    }
  },


  methods: { 

    openDialog() {
      if(this.readonly) {
        if(this.is3d) {
          let findIndex = this.medical.files.filter(item => item.file_type === this.getData.file_type)
                                            .findIndex(item => item.id === this.getData.id)
          this.getData.is3d = true
          this.store.commit("medical/SELECTED_IMAGE", this.getData)
          this.store.commit("medical/UPDATE_VISIBLE", findIndex)
          this.modals.showModal('showImage', !this.medicalModals.showImage)
        }else {
          this.store.commit("medical/SELECTED_IMAGE", this.getData)
          this.modals.showModal('showImage', !this.medicalModals.showImage)
        }
        return
      }
      this.$refs.file.click()
    },

  }
}
</script>