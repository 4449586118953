import * as yup from "yup"
import langs from "@/plugins/lang"


export default yup.object({ 

  fullName: yup
    .string()
    .required(langs.validation.REQUIRED('アカウント名')),
	
  username: yup
    .string()
    .required(langs.validation.REQUIRED('ログインID')),

  password: yup
    .string()
});

