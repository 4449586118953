import * as yup from "yup"
import langs from "@/plugins/lang"


export default yup.object({
  password: yup
    .string()
    .required(langs.validation.REQUIRED('パスワード')),
  passwordConfirmation: yup
    .string()
    .required(langs.validation.REQUIRED('パスワード(確認用)'))
    .oneOf([yup.ref('password'), null], langs.validation.PASSWORD_CONFIRMATION()),
});

