<template>
  <div id="layoutSidenav_content">
    <main>
      <div class="container-fluid">
        <div class="card">
          <div class="card-header">症例相談共有-一覧</div>
          <div class="card-body">    
<!--             <router-link class="btn btn-primary btn-main" to="/medical-manage/create"> 
              新規作成 
            </router-link> -->

            <div class="dataTable-container dataTable-container-scroll dataTable-medical-list">
              <table class="dataTable-table">
                <thead>
                  <tr>
                    <th>
                      <div class="fsc datepick">
                        <div class="input-group date">
                          <DatePicker 
                          format="yyyy/MM/dd"
                          :maxDate="filter.end_time" 
                          :enableTimePicker="false"
                          utc
                          v-model="filter.start_time" 
                          locale="ja" 
                          @update:modelValue="loadData()">
                          </DatePicker>
                        </div>
                        <span>～</span>
                        <div class="input-group date">
                          <DatePicker 
                           format="yyyy/MM/dd"
                          :minDate="filter.start_time" 
                          :enableTimePicker="false"
                          utc
                          v-model="filter.end_time" 
                          locale="ja" 
                          @update:modelValue="loadData()">
                          </DatePicker>
                        </div>
                      </div>
                    </th>
                    <th>
                      <div class="input-group iprup2 iprup fsc fixw">
                        <input 
                        class="form-control" 
                        type="text" 
                        placeholder="カルテ番号" 
                        aria-label="カルテ番号" 
                        v-model="filter.code" 
                         @keyup="loadData()"
                        >
                        <button 
                        class="btn btn-success" 
                        id="button-addon2"
                        type="button"
                        @click="loadData()"
                       
                        >
                          検索
                        </button>
                      </div>
                    </th>
                    <th>
                      <div class="input-group iprup3 iprup">
                        <input 
                        class="form-control" 
                        type="text" 
                        placeholder="患者名" 
                        aria-label="患者名" 
                        v-model="filter.full_name" 
                        @keyup="loadData()"
                        >
                        <button 
                        class="btn btn-success" 
                        id="button-addon2" 
                        type="button"     
                        @click="loadData()">
                          検索
                        </button>
                      </div>
                    </th>
                    <th>
                      <div class="select-style">
                        <MultiSelect 
                        v-model="filter.type"
                        :items="selectData"  
                        @selectedEvt="loadData"
                        >
                        </MultiSelect>
                      </div>
                    </th>
                    <!-- <th> 
                      <div class="w100px">閲覧数</div>
                    </th> -->
                    <th>
                      <div class="w100px">アクション</div>
                    </th>
                  </tr>
                </thead>
                <tbody v-if="medicals.data.length">
                  <tr v-for="item, index in medicals.data">
                    <td>{{ helpers.convertTzTo(item.created_at, true) }}</td>
                    <!-- <td>{{ item.code }}</td> -->
                    <td>{{ item.patient ? item.patient.special_code : ``}}</td>
                    <td> 
                      <a 
                      class="text-underline"
                      href="javascript:void(0)"
                      @click="filter.full_name = item.patient.full_name, 
                      loadData()
                      ">
                        {{ item.patient ? item.patient.full_name : `` }}
                      </a>
                    </td>
                    <td>
                      {{ showType(item) }}
                    </td>
                    <!-- <td>{{  `${item.views}回`  }}</td> -->
                    <td> 
                      <div class="fcc">
                        <ButtonView @handle="viewDetail(item.id)"></ButtonView>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <Pagination
             :maxVisibleButtons="medicals.lastPage < 5 ? medicals.lastPage : 5"
             :total-pages="medicals.lastPage"
             :per-page="medicals.itemPerPage"
             :current-page="medicals.currentPage"
             :total="medicals.total"
             @pagechanged="loadData"
            ></Pagination>

          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<style scoped src="@/assets/css/bootstrap.min.css">
</style>


<script type="text/javascript">
import MultiSelect from "@/components/MultiSelectCheckbox"
import Pagination from "@/components/pagination/Default"

import helpers from "@/plugins/helper"
import { useStore } from 'vuex'
import { computed, ref, watch } from 'vue'
import { useRouter } from 'vue-router'

import _ from "lodash"

export default {

  components: {
    MultiSelect,
    Pagination
  },

  setup() {
    const store = useStore()
    const router = useRouter() 
    let filter =  {
      start_time: null,
      end_time: null,
      full_name: null,
      type: []
    }
    let medicals = computed(() => store.getters["medical/medicals"])
    let selectData = [
        "すべて",
        "PCR",
        "画像",
        "STL",
    ]
    function loadData(page) {
      let payload = {
        page: typeof page !== 'undefined' ? page : 1,
        start_time: helpers.convertTzTo(filter.start_time, false, '-'),
        end_time: helpers.convertTzTo(filter.end_time, false, '-'),
        code: filter.code,
        full_name: filter.full_name,
        type: filter.type
      }

      if(payload.type.length && payload.type.includes("すべて")) {
        payload.type = selectData
      }

      store.dispatch("medical/fetchAll", payload)
    }

    loadData()

    return {
      medicals: medicals,
      router,
      filter,
      helpers,
      loadData: _.debounce(loadData, 500),
      selectData
    }
  },

  methods: {
    viewDetail(id) {
      this.router.push({name: "MedicalManageDetail", params: {id: id} })
    },
    showType(medical) {
      let image = medical.images.length ? "画像" : ``
      let pcr = medical.medical_attributes ? (medical.medical_attributes.find(item => item.attribute_id === 1) ? `PCR` : ``) : ``
      let file = medical.files.length ?  `STL` : ``
      return [image, pcr, file].filter(Boolean).join('/');
    }
  }

}
</script>