import langs from "@/plugins/lang"
import helpers from '@/plugins/helper'
import AttributeService from "@/services/attribute"
import toastPlugin from "@/plugins/toast"

export  const getters = {
  attributes: state => state.attributes,
};

export const getDefaultState = () => ({
  attributes: []
})

export const state = getDefaultState

export  const actions = {

  async fetchAll({commit}, payload) {
    const params = payload
    const res = await AttributeService.fetchAll(params)
    if(res.status === 200) {
      return res.data.data
      commit("FETCH_ALL", res.data.data)
    }
  },
}

export  const mutations = {

  FETCH_ALL(state, data) {
    const attributes = state.attributes
    state.attributes = data
  },

}


export default {
    namespaced: true,
    getters,
    state,
    actions,
    mutations
}
