<template>

<div id="layoutSidenav_nav" :class="!openMenu ? `close` : ``">
   <nav class="sb-sidenav accordion sb-sidenav-light" id="sidenavAccordion">
      <div class="sb-sidenav-menu">
         <div class="nav">                 
            <router-link v-for="(item, index) in menus" :class="[`nav-link menu4`]" :key="index" :to="item.path" >
                <img :src="item.img" alt="カルテ管理">
              {{ item.title }} 
            </router-link>
            <div v-if="!helpers.isMobile()">
              <span class="icon-shrink2" v-if="openMenu" @click="openMenu = false"></span>
              <span class="icon-enlarge2" v-else @click="openMenu = true"></span>
            </div>
          </div>
      </div>
   </nav>
</div>


</template>
<script type="text/javascript">
import { inject } from "vue";

export default {

  data()  {
    return {
      openMenu: true
    }
  },

  setup() {
    let helpers = inject('helpers')
    let menus = [
      {
        title: "症例相談共有",
        img: "/assets/common_img/icon4.svg",
        path: "/medical-manage",
      },
      {
        title: "アカウント管理",
        img: "/assets/common_img/icon5.svg",
        path: "/profile",
      },
      {
        title: "ログアウト",
        img: "/assets/common_img/icon7.svg",
        path: "/logout",
      }
    ]
    return {
      menus,
      helpers
    }
  }
}
</script>