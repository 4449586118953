import Api from '@/plugins/api'
import helpers from "@/plugins/helper"

export default {

  prefix: '/orthodontists/medical-records',

  async fetchAll(params) {
    try {
      return await Api.get(`${this.prefix}`, {
        params: params
      });
    } catch (error) {
      return helpers.getError(error) || false; 
    }
  },

  async fetch(id) {
    try {
      return await Api.get(`${this.prefix}/${id}`);
    } catch (error) {
      return helpers.getError(error) || false; 
    }
  },

   async delete(id) {
    try {
      return await Api.delete(`${this.prefix}/${id}`);
    } catch (error) {
      return helpers.getError(error) || false; 
    }
  },

}
