<template>
  <div id="MultiSelect">
    <div class="dropdown select-style" @click="showDropdown">
      <div class="overselect"></div>
      <select class="form-control">
        <option> {{ showSelected() }} </option>
      </select>
    </div>
    <div
    v-if="show"
    class="multiselect"
    >
      <ul>
        <li v-for="(value, index) in items" :key="index">
          <input
          v-model="selected"
          :value="value"
          :id="value"
          type="checkbox"
          @change="selectedEvt(value)"
          >
          <label :for=value>{{ value }}</label>
        </li>
      </ul>
    </div>
  </div>
</template>


<script type="text/javascript">
import { ref, watch } from 'vue'

import _ from "lodash"

export default {

  emits: ["update:modelValue", "selectedEvt"],

  props: {
    items: {
      type: Array,
      required: true
    },
    modelValue: {
      type: [Array, Object],
      default: null
    } 
  },


  setup(props) {
    const selected = ref(props.modelValue)
    watch(selected, (data) =>  {

    })

    return {
      selected
    }
  },

  data() {
    return {
      show: false,
    }
  },

  methods: {
    showDropdown() {
      this.show = !this.show
    },
    showSelected() {
      if(this.selected.length <= 0) {
        return "アップロード内容"
      }else {
        if(this.selected.length > 2) {
          return "すべて"
        }else {
          return this.selected.join(", ") 
        }
      }
    },

    selectedEvt(value) {

      if(value === "すべて") {
        if(this.selected.includes("すべて")) {
           this.selected = ["すべて", "PCR", "画像", "STL"]
        }else {
          this.selected = []
        }
      }else {
        this.selected = _.without(this.selected, 'すべて');
      }

      setTimeout(() => {
        this.$emit("update:modelValue", this.selected)
        this.$emit("selectedEvt")
      }, 500)
    }
  },

}
</script>

<style lang="scss" scoped>

#MultiSelect {

  .dropdown { 
    position: relative; 
    cursor: pointer;
  }

  .multiselect {
    position: relative;
    
    ul {
      border: 1px solid #ddd;
      border-top: 0;
      border-radius: 0 0 3px 3px;
      left: 0px;
      padding: 8px 8px;
      position: absolute;
      top: -1rem;
      width: 100%;
      list-style: none;
      max-height: 150px;
      overflow: auto;
      background-color: #fff;
      top: 10px;
      display: flex; 
      flex-wrap: wrap;
      li {
        flex: 50%;
        display: flex;
        label {
          font-weight: normal;
          padding-left: 5px;
          margin-top: -5px;
        }
      }
    }
  }

  .overselect {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }

  .col {
    flex: 0 0 50%;
    max-width: 50%;
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

</style>

