import * as yup from "yup"
import langs from "@/plugins/lang"


export default yup.object({
  email: yup
    .string()
    .required(langs.validation.REQUIRED('メールアドレス')),
});

