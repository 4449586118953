<template>
  <div class="modal show" id="image" tabindex="-1" role="dialog">
    <div
      :class="
        getImage.is3d
          ? 'modal-dialog modal-dialog3D'
          : 'modal-dialog modal-dialogImage'
      "
      role="document"
    >
      <div class="modal-content modal-content-fix">
        <div class="modal-header">
          <button
            class="close"
            type="button"
            aria-label="Close"
            @click="modals.showModal('showImage', !medicalModals.showImage)"
          >
            <span aria-hidden="true"> <i class="fa fa-times"></i></span>
          </button>
<!--            <button
            class="close"
            type="button"
            aria-label="Close"
            @click="close()"
          >
            <span aria-hidden="true"> <i class="fa fa-times"></i></span>
          </button> -->
        </div>
        <div class="modal-body" v-if="getImage.is3d">
          <div class="progress" v-if="progress && progress < 100">
            <div class="bar" :style="{ width: progress + '%' }">
            </div>
          </div>
          <!--  <h4 class="text_center" style="color: #02A3AF;">{{ getImage.title }} </h4>
          <model-viewer
            class="model-3d"
            :src="helpers.show3D(getImage.path)"
            alt="A 3D model of an astronaut"
            shadow-intensity="1"
            camera-controls
            auto-rotate
            touch-action="pan-y"
          ></model-viewer> -->
          <div class="model-3d">
            <canvas id="myCanvas" ></canvas>
          </div>
          <div
            :class="
              medical.files.filter(
                (item) => item.file_type === getImage.file_type
              ).length >= 2
                ? 'groupBtn'
                : 'hidebtn'
            "
          >
            <a
              v-for="(value, index) in medical.files.filter(
                (item) => item.file_type === getImage.file_type
              ).length"
              type="button"
              :class="['btn ', visible === index ? `btn-primary` : `btn-white`]"
              @click="showTeeth(getImage.file_type, index)"
              >{{ showLabelTeeth(index) }}
            </a>
          </div>
        </div>
        <div class="modal-body" v-else>
          <h4 class="text_center" style="color: #02a3af">
            {{ getImage.title }}
          </h4>
          <img
            :src="helpers.showImageURL(getImage.path)"
            style="
              margin: 0 auto;
              display: block;
              max-width: 600px;
              height: auto;
            "
            class="mt20 mb20"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.modal-dialog {
  @media (max-width: 960px) {
    width: 94vw;
    height: 94vh;
    max-width: 100%;
    max-height: 100%;
  }
}

.progress {
  margin: 20px auto;
  padding: 0;
  width: 90%;
  height: 10px;
  overflow: hidden;
  background: #e5e5e5;
  border-radius: 6px;
}

.bar {
  position: relative;
  float: left;
  min-width: 1%;
  height: 100%;
  background: cornflowerblue;
}

.percent {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin: 0;
  font-family: tahoma, arial, helvetica;
  font-size: 12px;
  color: white;
}

.model-3d {
  margin: 0 auto;

  @media (max-width: 960px) {
    height: 80vh;

    width: 960px;
  }

  #myCanvas {
    width: 100%;
    height: 90%;
    display: block; 
    margin: 0 auto;
  }

  .userInput {
    top: -10%;
  }
}
</style>

<script type="text/javascript">
import { inject, ref } from "vue";
import { useStore } from "vuex";
import { computed } from "vue";
import { useRoute, useRouter } from "vue-router";
import * as THREE from "three";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { TrackballControls } from "three/examples/jsm/controls/TrackballControls.js";
import toastPlugin from "@/plugins/toast";

export default {
  setup() {
    const route = useRoute();
    const store = useStore();
    const payload = {
      id: route.params.id,
    };
    store.dispatch("medical/fetch", payload);
    const modals = inject("modals");
    const helpers = inject("helpers");
    const medicalModals = store.getters["medical/modals"];
    const getImage = store.getters["medical/imageSelected"];
    let visible = store.getters["medical/visibleSelected"];

    const label = "";
    const medical = store.getters["medical/medical"];

    function showLabelTeeth(index) {
      switch (index) {
        case 0:
          return "上顎";
        case 1:
          return "下顎";
        case 2:
          return "噛み合わせ";
        default:
      }
    }

    return {
      visible: computed(() => store.getters["medical/visibleSelected"]),
      modals,
      helpers,
      medicalModals,
      getImage: computed(() => store.getters["medical/imageSelected"]),
      medical: computed(() => store.getters["medical/medical"]),
      showLabelTeeth,
      store,
    };
  },

  methods: {
    close() {
      window.location.reload();
    },

    showTeeth(fileType, index) {
      let getFile = this.medical.files.filter(
        (item) => item.file_type === fileType
      );
      if (fileType === "ply") {
        getFile[index].is3d = true;
      }

      this.store.commit("medical/SELECTED_IMAGE", getFile[index]);
      this.store.commit("medical/UPDATE_VISIBLE", index);
      this.loadModel();
    },

    loadModel() {
      let myCanvas = document.getElementById("myCanvas");
      let fileUrl = this.helpers.show3D(this.getImage.path);
      let renderer = new THREE.WebGLRenderer({
        canvas: myCanvas,
        antialias: true,
        autoClearColor: true,
      });
      renderer.setClearColor(0xffffff);
      renderer.setPixelRatio(window.devicePixelRatio);
      let scene = new THREE.Scene();

   
      renderer.setSize(myCanvas.offsetWidth, myCanvas.offsetHeight);
      const camera = new THREE.PerspectiveCamera(
        50,
        window.innerWidth / window.innerHeight,
        0.1,
        1000
      );
      camera.position.z = 80;

      // set original color
      renderer.outputEncoding = THREE.sRGBEncoding;
      renderer.toneMapping = THREE.ACESFilmicToneMapping;
      renderer.physicallyCorrectLights = true;

      let controls = new TrackballControls(camera, renderer.domElement);
      controls.rotateSpeed = 5
      controls.addEventListener("change", render);


      const loader = new GLTFLoader();
      loader.load(
        fileUrl,
        // Load model successfully
        function (gltf) {
          let model = gltf.scene;
          let geometry = model.children[0].geometry;
          let newMaterial = new THREE.MeshBasicMaterial({
            vertexColors: THREE.VertexColors,

          });

          newMaterial.color.setHex(0xffffff);
          const mesh = new THREE.Mesh( geometry, newMaterial );
          scene.add(mesh);
        },
        // Progress loading model
        (progressEvent) => {
          this.progress = Math.round(
            (progressEvent.loaded / progressEvent.total) * 100
          );
        },
        // Error on loading model
        (errorEvent) => {
          this.progress = 100;
          toastPlugin.error("File error");
        }
      );



      animate();

      function animate() {
        requestAnimationFrame(animate);
        controls.update();
        render();
      }
      function render() {
        renderer.render(scene, camera);
      }
    },
  },

  data() {
    return {
      progress: 0,
    };
  },

  mounted() {
    if(this.getImage && this.getImage.is3d) {
      this.loadModel();
    }
  
  },
};
</script>
